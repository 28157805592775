import type { ExternshipStatus } from 'bundles/OperationsDashboard/shared';

import type { FrontChatOptions } from 'lib/hooks/useDisableWidgets';

import type {
  ApiCourseData,
  ApiCourseNavGroup,
  CourseModule,
  ProgressStats,
} from 'lib/courseData';

import type { ChecklistStage } from 'bundles/Classroom/components/CareerChecklist/data';
import type { JobSearchData } from 'lib/jobSearchData';

import type { EnrollmentFeatureFlags } from 'queries/feature_flags';

import type { OnboardingData } from './Onboarding';
import type { PaymentPortalData } from './PaymentPortal';

export type Coach = {
  avatar: string | null;
  name: string;
  email: string | null;
  community_link: string | null;
  calendly_url: string | null;
};

export type Instructor = {
  id: RecordId;
  name: string;
  email: string;
  avatar: string | null;
};

export type OptInStatus = 'pending' | 'opted_in' | 'opted_out';

export type AccountabilityContact = {
  opt_in_link: string;
  profile: {
    first_name: string;
    last_name: string | null;
    phone_number: string;
    status: OptInStatus;
  } | null;
};

export type Enrollment = {
  feature_flags: EnrollmentFeatureFlags;
  id: RecordId;
  can_access_career_portal: boolean;
  can_access_resume_services: boolean;
  career_services_opening_week: number;
  job_coaching_eligible: boolean;
  line_of_business: 'dtc' | 's4b' | 's4g';
  learning_coach_email: string | null;
  sponsor_type: string | null;
  graduation_status: 'graduated' | 'failed' | null;
  graduated_at: TimestampString | null;
  sponsor_name_label: string | null;
  goal_employer_label: string | null;
  instructor: Instructor | null;
  curriculum: {
    slug: string;
    name: string;
    short_name: string;
    certification_name: string;
    requires_externships: boolean;
  };
  cohort: {
    slug: string;
    name: string;
    start_date: DateString;
    first_sunday: DateString;
    end_date: DateString;
    nha_rebate_date: DateString;
    certification_start_prompting_date: DateString;
    timezone: string;
    default_lecture_time: string;
    current_week_index: number;
    first_monday: DateString;
  };
  cohort_timing: {
    class_timing_description: string;
    lecture_start_time: string;
    kickoff_date: DateString;
  } | null;
  group: {
    name: string;
  } | null;
};

export type ResumeStatus =
  | 'submitted'
  | 'questions_submitted'
  | 'answers_submitted'
  | 'finished_round_1'
  | 'finished_round_2';

export type Resume = {
  status: ResumeStatus;
  download_link: string | null;
  edit_link: string | null;
  original_file: ActiveStorageFile | null;
  resume_questions: ResumeQuestion[];
  id: RecordId;
};

export type ResumeQuestionType =
  | 'phone_number'
  | 'city_and_state'
  | 'city'
  | 'state'
  | 'award_name'
  | 'award_institution'
  | 'year'
  | 'company'
  | 'job_title'
  | 'month_and_year'
  | 'open_ended';

export type ResumeQuestion = {
  question: string;
  question_type: ResumeQuestionType;
  answer: string;
  id: RecordId;
};

// Note: keep in sync with Profile::CovidVaccinationStatus
export type CovidVaccineStatus =
  | 'dose_1'
  | 'dose_2'
  | 'dose_3'
  | 'planning'
  | 'not_planning';

// Note: keep in sync with ExternshipCompany::VACCINATION_OPTIONS
export type VaccinationOptions =
  | 'covid-19'
  | 'influenza'
  | 'mmr'
  | 'tuberculosis'
  | 'hepatitis-b'
  | 'varicella-chickenpox'
  | 'tdap'
  | 'polio'
  | 'typhoid'
  | 'rabies'
  | 'meningitis-acyw'
  | 'meningitis-b'
  | 'pneumococcal-pneumonia';

export type Clinic = {
  name: string;
  address: string | null;
  contact_name: string | null;
  phone: string | null;
  email: string | null;
  self_interview_scheduling: boolean;
  is_open: boolean | null;
  today_open_time: string | null;
  today_close_time: string | null;
};

export type LocationPreference = {
  zip_codes: string[];
  additional_info: string;
  distance_threshold: number;
};

export type Externship = {
  externship_id: RecordId;
  status: ExternshipStatus | null;
  opt_out_at: TimestampString | null;
  opt_in_at: TimestampString | null;
  suspended_at: TimestampString | null;
  agree_to_internal_contract: boolean;
  curriculum_slug: string;

  coordinator_reachout_date: DateString;
  assumed_start_date: DateString;
  assumed_start_date_month: number;
  interview_scheduling_deadline: DateString;
  covid_vaccine_status: CovidVaccineStatus;
  latest_covid_vaccine_date: DateString | null;
  required_vaccinations: VaccinationOptions[] | null;
  has_completed_required_vaccinations: boolean;
  preview_clinics: string[];

  expected_start_date?: DateString | null;
  start_date?: DateString | null;
  end_date?: DateString | null;
  docs?: {
    clinic_timesheet_doc: ActiveStorageFile | null;
    clinic_evaluation_doc: ActiveStorageFile | null;
  };
  clinic?: Clinic | null;
  coordinator?: {
    name: string;
    email: string;
  } | null;
  interview_at?: TimestampString | null;

  show_externship_details: boolean;
  start_contacting_clinic_at: TimestampString | null;
  has_contacted_clinic: boolean | null;
  has_connected_with_clinic: boolean | null;
  can_manually_confirm_connected_with_clinic: boolean | null;
  site_cannot_host_student: boolean | null;
  has_appealed_suspension: boolean | null;
  site_confirmed_at: TimestampString | null;
  externship_daily_trackers: ExternshipDailyTracker[];
  can_submit_externship_nps_survey: boolean;
  location_preference: LocationPreference | null;
  num_available_clinics_within_preview_radius: number | null;
  call_counts_per_day?: Record<string, number>;
};

export type ExternshipUpdates = {
  [attribute in keyof Externship['docs']]?: File | null;
} & {
  agree_to_internal_contract?: boolean;
  interview_at?: TimestampString;
  status?: Extract<ExternshipStatus, 'site_confirmed'>;
  site_cannot_host_student?: boolean;
  job_coaching?: boolean;
  opted_out?: boolean;
};

export type ExternshipClinicCallAttemptUpdates = {
  connected?: boolean;
  student_note?: string;
};

export type ExternshipClinicCallAttemptCreateBody = {
  connected: boolean;
};

// matches CareerController#questionnaires_map
export type CareersFormCategory =
  | 'career_questionnaire'
  | 'career_reapplication'
  | 'career_exit_questionnaire'
  | 'post_externship'
  | 'externship_availability'
  | 'externship_search_preference'
  | 'pre_externship_daily_check_in'
  | 'ongoing_externship_daily_check_in'
  | 'externship_onboarding_packet'
  | 'externship_interview_outcome'
  | 'externship_nps';

export type CareerModule = {
  id: string;
  label: string;
  start_at: DateString;
  end_at: DateString;
  completed?: boolean;
};

type CareerTasks = {
  stages: ChecklistStage[];
};

export type ScreeningReport = {
  id: number;
  screening_type: 'drug' | 'background';
  external_student_url: string;
  expires_at: Date;
};

export type ExternshipDailyTracker = {
  entry_date: DateString;
  entry_data: {
    id?: RecordId;
    entry_notes?: string;
    lunch_start_time?: DateString;
    lunch_end_time?: DateString;
    work_start_time?: DateString;
    work_end_time?: DateString;
    mood?: number | null;
    hours_worked?: number;
  };
};

export type CareerData = {
  career_tasks: CareerTasks | null;
  career_enrolled: boolean;
  career_enrollable: boolean;
  career_eligible: boolean;
  career_exit_eligible: boolean;
  externship_opted_out: boolean;
  externship_eligible: boolean;
  job_coaching_opted_out: boolean;
  job_coaching_eligible: boolean;
  career_previously_opted_in: boolean;
  hired: boolean;
  coach: Coach | null;
  expected_coaching_start_date: DateString | null;
  resume: Resume | null;
  can_upload_resume: boolean;
  missing_clinic_docs: boolean;
  externship: Externship | null;
  career_forms: {
    [category in CareersFormCategory]?: {
      typeform_id: string | null;
      hidden_fields?: Record<string, string>;
      resubmittable: boolean;
      submitted: boolean;
      submitted_today?: boolean;
    };
  };
  screening_reports: ScreeningReport[];
  career_widget_options: CareerWidgetOptions;
  likely_to_graduate: boolean;
  contact_clinic_from_number: string;
  career_services_opening_week: number;
};

// misc. enrollment-specific data
type ResourcesData = {
  community: {
    community_link: string | null;
    joined: boolean;
    joined_peers_count: number | null;
    channel_names: { [key in 'group' | 'peer_group']: string | null };
  } | null;
  motivation_photo: string | null;
  referral_dashboard_link: string | null;
};

// as defined in lib/classroom_widget_options.rb
export type CourseWidgetOptions = {
  certification_widget: {
    hide_rebate: boolean;
    alternate_mycaa_copy: boolean;
  };
  community_widget: Record<string, never>;
  course_progress_widget: Record<string, never>;
  peer_group_widget: Record<string, never>;
  overdue_tasks_widget: Record<string, never>;
  graduation_warnings_widget: Record<string, never>;
};

// as defined in lib/classroom_widget_options.rb
export type CareerWidgetOptions = {
  career_services_reminder_widget: {
    hide: boolean;
    alternate_train_to_hire_copy: boolean;
    alternate_upskill_copy: boolean;
    alternate_apprenticeship_copy: boolean;
  };
  career_services_survey_widgets: {
    hide: boolean;
  };
  job_search_widget: {
    hide: boolean;
  };
  career_coach_widget: {
    hide: boolean;
  };
  career_checklist_widget: {
    hide: boolean;
  };
  career_resources_widget: {
    hide: boolean;
  };
  resume_widget: {
    hide: boolean;
  };
  career_contract_widget: {
    hide: boolean;
  };
  hired_widget: {
    hide: boolean;
  };
  externship_widget: {
    hide: boolean;
  };
};

type ConfigData = {
  front_chat_options?: FrontChatOptions;
  stripe_public_key: string;
};

type CareerResourcesCourseData = {
  resources_course_data: {
    modules: CourseModule[];
    nav_groups: ApiCourseNavGroup[];
  } | null;
};

type DynamicPath<_Data> = (enrId: number) => string;
const enrPath =
  <Data>(path: string): DynamicPath<Data> =>
  (enrId) =>
    `/enrollments/${enrId}${path}`;

export const DYNAMIC_DATA_MAP = {
  payment_portal: enrPath<PaymentPortalData>('/payments'),
  onboarding: enrPath<OnboardingData>('/onboarding'),
  career: enrPath<CareerData>('/career'),
  resources: enrPath<ResourcesData>('/classroom/resources_info'),
  job_search_data: enrPath<JobSearchData>('/job_search_forms'),
  course_data: enrPath<ApiCourseData>('/progress/course_outline'),
  progress_stats: enrPath<ProgressStats>('/progress'),
  career_resources_course_data: enrPath<CareerResourcesCourseData>(
    '/career/career_resources_course_data',
  ),
};

export type DynamicData = {
  [Key in keyof typeof DYNAMIC_DATA_MAP]: typeof DYNAMIC_DATA_MAP[Key] extends DynamicPath<
    infer Data
  >
    ? Data
    : never;
};

export type ClassroomProps = Record<string, unknown> & {
  config: ConfigData;
};
