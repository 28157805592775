import { forwardRef } from 'react';
import {
  Link as RouterLink,
  Redirect,
  useLocation as useLocationOriginal,
} from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { createBrowserHistory } from 'history';

import {
  LocationDescriptor,
  NavType,
  urlNavigationTypeFactory,
} from 'lib/routeBuilder';
import { isReleaseMismatch } from 'lib/releaseManager';

// import all route definitions here:
import { ROUTES as authRoutes } from 'bundles/Signup/routes';
import { ROUTES as classroomRoutes } from 'bundles/Classroom/routes';
import { ROUTES as operationsRoutes } from 'bundles/OperationsDashboard/routes';

// NOTE: import these from this file instead of react-router-dom,
// this allows us to easily change the router library or logic in the future.
export { Redirect };

export const useLocation = useLocationOriginal<RouterState | undefined>;

declare global {
  // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
  interface Window {
    browserRouterHistory?: ReturnType<typeof createBrowserHistory>;
  }
}

// `location.state` shape
export type RouterState = {
  /**
   * indicate that the navigation should be "shallow"
   * i.e. no page data refresh (see `lib/pageData.tsx`)
   */
  shallow?: boolean;
};

// only create one instance of the History
export const getBrowserHistory = () =>
  (window.browserRouterHistory ||= createBrowserHistory());

export const urlNavigationType = urlNavigationTypeFactory(
  Object.values(authRoutes),
  Object.values(classroomRoutes),
  Object.values(operationsRoutes),
);

export type LinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  newTab?: boolean;
  /** If the given `href` is "external", open link in a new tab */
  newTabIfExternal?: boolean;
  /** You can optionally pass a pre-computed `navType`, then `<Link/>` won't need to compute it again */
  navType?: NavType;
};

const pushRouteFactory =
  (method: 'push' | 'replace') =>
  (
    location: string | (LocationDescriptor & { state?: RouterState }),
    state?: RouterState,
  ) => {
    if (location !== null && typeof location === 'object' && location.state) {
      state = location.state;
    }

    const [navType, cleanHref] = urlNavigationType(location);

    if (isReleaseMismatch() && !state?.shallow) {
      window.location.href = cleanHref;
      return;
    }

    if (navType === 'frontend') getBrowserHistory()[method](cleanHref, state);
    else window.location.href = cleanHref;
  };
export const Router = {
  push: pushRouteFactory('push'),
  replace: pushRouteFactory('replace'),
  get location() {
    return getBrowserHistory().location;
  },
};

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      href,
      children,
      navType: precomputedNavType,
      newTab,
      newTabIfExternal,
      onClick,
      ...rest
    },
    ref,
  ) => {
    if (!href) throw new Error('Link requires href prop');

    const location = useLocation();
    const [navType, cleanHref] = precomputedNavType
      ? [precomputedNavType, href]
      : urlNavigationType(href, location);

    if (newTab || (newTabIfExternal && navType === 'external')) {
      rest.target = '_blank';
      rest.rel = 'noopener noreferrer';
    }

    if (navType === 'frontend')
      return (
        <RouterLink
          ref={ref}
          to={cleanHref}
          onClick={(e) => {
            onClick?.(e);
            if (e.defaultPrevented) return;

            if (isReleaseMismatch()) {
              window.location.href = cleanHref;
              e.preventDefault();
            }
          }}
          {...rest}
        >
          {children}
        </RouterLink>
      );
    else
      return (
        <a ref={ref} href={cleanHref} {...rest}>
          {children}
        </a>
      );
  },
);
