import { cloneElement, isValidElement } from 'react';
import * as _ from 'lodash-es';

export const pluralize = (count: number, singular: string) => {
  if (count === 1) return singular;
  if (singular.endsWith('s')) return singular + 'es';
  return singular + 's';
};

export const singularize = (plural: string) => {
  if (plural.endsWith('ses')) return plural.slice(0, -2);
  if (plural.endsWith('s')) return plural.slice(0, -1);
  return plural;
};

export const formatAsPercent = (val: number) => Math.round(val * 100.0) + '%';

export const parseMarkupLink = (
  str: string,
): [label: string, link: string | null] => {
  const m = str.match(/^\[(.+?)\]\((.+?)\)$/);
  if (!m) return [str, null];
  const [, name, link] = m;
  return [name, link];
};

export const toSentence = (
  els: React.ReactNode[],
  join: React.ReactNode = 'and',
  joinBefore: React.ReactNode = ', ',
) => {
  const next: React.ReactNode[] = [];
  if (els.length <= 1) return els[0] ?? null;
  if (els.length === 2) next.push(els[0], <> {join} </>, els[1]);
  else {
    for (let i = 0; i < els.length; i++) {
      next.push(els[i]);
      if (i < els.length - 1)
        next.push(
          i < els.length - 2 ? (
            joinBefore
          ) : (
            <>
              {joinBefore}
              {join}{' '}
            </>
          ),
        );
    }
  }

  return next.map((el, i) =>
    isValidElement(el)
      ? cloneElement(el, { key: el.key ?? `_toSentence_${i}` })
      : el,
  );
};

// inspired by https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
// this is not a perfect solution, but it's good enough for our purposes
export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

const ROUND_TO_FRACTIONS: [numerator: number, denominator: number][] = [
  [1, 4],
  [1, 3],
  [1, 2],
  [2, 3],
  [3, 4],
];
export const formatAsFraction = (n: number, minWholeNumber = 2): string => {
  if (Number.isInteger(n)) return n.toString();
  if (n >= minWholeNumber) return Math.round(n).toString();

  const whole = Math.floor(n);
  const decimal = n - whole;

  // find the closest fraction
  const [numerator, denominator] = ROUND_TO_FRACTIONS.reduce(
    (best, [num, den]) => {
      const current = Math.abs(num / den - decimal);
      const bestVal = Math.abs(best[0] / best[1] - decimal);
      return current < bestVal ? [num, den] : best;
    },
    [1, 1],
  );

  return `${whole > 0 ? whole + ' ' : ''}${numerator}/${denominator}`;
};

// forked from Rails `ActiveSupport::Inflector.parameterize`
// https://github.com/rails/rails/blob/19eebf6d33dd15a0172e3ed2481bec57a89a2404/activesupport/lib/active_support/inflector/transliterate.rb#L123
export const parameterize = (string: string, separator = '-') => {
  return (
    // Replace accented chars with their ASCII equivalents.
    _.deburr(string)
      // to lower case
      .toLowerCase()
      // Turn unwanted chars into the separator.
      .replace(/[^a-z0-9\-_]+/g, separator)
      // No more than one of the separator in a row.
      .replace(
        separator === '-' ? /-{2,}/ : RegExp(`${separator}{2,}`),
        separator,
      )
      // Remove leading/trailing separator.
      .replace(
        separator === '-' ? /^-|-$/ : RegExp(`^${separator}|${separator}$`),
        '',
      )
  );
};
